<template>
  <v-row no-gutters justify="center">
    <v-col cols="12" md="8" lg="6">
      <v-alert v-if="success" text dense type="success">{{ success }}</v-alert>

      <v-alert v-if="error" text dense type="error">{{ error }}</v-alert>

      <v-form>
        <!-- Profile -->
        <template>
          <pibot-form-block-profile ref="profileForm" />
          <v-divider class="my-3" />
        </template>

        <!-- Password reset -->
        <template>
          <pibot-settings-password-new v-if="$grantAccess($me, 'createAny', 'profile')" ref="passwordForm" @error="setError" @success="setSuccess" @loading="setLoadingState" />
          <v-divider class="my-3" />
        </template>

        <!-- Access Control -->
        <template>
          <pibot-form-block-ac ref="acForm" />
          <v-divider class="my-3" />
        </template>

        <!-- Form actions -->
        <v-row justify="space-between">
          <v-col cols="auto">
            <v-btn
              color="success"
              depressed
              small
              :loading="loading"
              @click="validateAndUpdate"
            >
              Create profile
            </v-btn>
          </v-col>

          <!-- <v-col cols="auto">
            <v-btn
              color="success"
              depressed
              small
              :loading="loading"
              disabled
            >
              Cancel
            </v-btn>
          </v-col> -->
        </v-row>
      </v-form>

      <pibot-confirm-exit-dialog
        :value="dialog"
        :to="to"
        @decline="dialog = false"
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import refValidationsMixin from '@/utils/mixins/forms/refValidations.mixin'

export default {
  name: 'pibot-settings-new-user',
  mixins: [refValidationsMixin],
  components: {
    'pibot-form-block-profile': () => import('@/components/form-blocks/profile/Profile'),
    'pibot-settings-password-new': () => import('@/components/form-blocks/profile/PasswordNew'),
    'pibot-form-block-ac': () => import('@/components/form-blocks/profile/AccessControl'),
    'pibot-confirm-exit-dialog': () => import('@/components/dialogs/ConfirmExit')
  },
  data: () => ({
    success: false,
    error: false,
    initialized: false,
    dialog: false,
    to: null
  }),
  computed: {
    ...mapState({
      users: state => state.settings.users,
      editing: state => state.settings.editing,
      loading: state => state.settings.loading
    })
  },
  methods: {
    ...mapMutations({
      setEditingUser: 'settings/SET_EDITING_USER',
      setLoadingState: 'settings/SET_LOADING_STATE'
    }),
    ...mapActions({
      fetchUsers: 'settings/socket_fetchUsers',
      tryProfileCreate: 'settings/socket_tryProfileCreate'
    }),
    initEditingUser () {
      const editing = {
        username: '',
        email: '',
        role: 'user'
      }

      this.setEditingUser(JSON.parse(JSON.stringify(editing)))
    },
    clear () {
      this.initEditingUser()
    },
    reset () {
      this.setSuccess(false)
      this.setError(false)
      this.setLoadingState(false)
      this.setEditingUser(null)
      this.clear()
    },
    validate () {
      let valid = true

      /**
       * Loop through $refs and execute their validate() functions.
       * Break if any is invalid.
       */
      for (const key in this.$refs) {
        const refIsValid = this.$refs[key].validate()
        if (!refIsValid) {
          valid = false
          break
        }
      }

      return valid
    },
    validateAndUpdate () {
      const isValid = this.validate()
      if (!isValid) return

      this.setLoadingState(true)

      const payload = {
        user: {
          ...this.editing,
          password: this.$refs.passwordForm.password
        },
        passwordEval: this.$refs.passwordForm.passwordEval
      }

      this.tryProfileCreate(payload)
        .then((_id) => {
          this.$router.push({ path: `/settings/user/created/${_id}` })
        })
        .catch(error => {
          this.setSuccess(false)
          this.setError(error.message)
        })
        .finally(() => {
          this.setLoadingState(false)
        })
    },
    setError (error) {
      this.error = error
    },
    setSuccess (message) {
      this.success = message
    }
  },
  beforeRouteEnter (to, from, next) {
    // called before the route that renders this component is confirmed.
    // does NOT have access to `this` component instance,
    // because it has not been created yet when this guard is called!
    next(vm => {
      if (vm.initialized) return // Check if already initialized so we don't execute this twice
      vm.initialized = true

      vm.$grantAccess(vm.$me, 'createAny', 'profile', true)

      // Reset form upon enter as the component could already be created
      vm.reset()
    })
  },
  beforeRouteLeave (to, from, next) {
    // called when the route that renders this component is about to
    // be navigated away from.
    // has access to `this` component instance.

    if (!this.loading && !this.dialog && this.checkDirty()) {
      this.dialog = true
      this.to = to
    } else {
      this.dialog = false
      next()
      // Always remove the editing user when exiting this route
      this.setEditingUser(null)
    }
  },
  created () {
    if (this.initialized) return // Check if already initialized so we don't execute this twice
    this.initialized = true

    this.$grantAccess(this.$me, 'createAny', 'profile', true)

    // Reset form upon creation
    this.reset()
  }
}
</script>
